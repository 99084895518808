import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import './BookCard.css';

class BookCard extends Component {
  state = {};
  render() {
    return (
      <Card style={{ width: "18rem", height: "100%" }}>
        <Card.Img variant="top" src={this.props.img} />
        <Card.Body style={{position: 'relative'}}>
          <Card.Title>{this.props.title}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">{this.props.author}</Card.Subtitle>
          <Card.Text>
            Isbn: {this.props.isbn}
            <br></br>
            Supports the full syllabus for examination from 2023.
          </Card.Text>
          <Button variant="primary" className="button" href={`/book/${this.props.isbn}`} style={{
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "4rem",
           }}><span>Read Book</span></Button>
        </Card.Body>
      </Card>
    );
  }
}

export default BookCard;
