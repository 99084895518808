import React from "react";
import { useParams } from "react-router-dom";
// import NavBar from "../components/Navbar";
import PdfViewer from "../components/PdfViewer";

export default function Ebook(props) {
  const { isbn } = useParams();
  return (
    <div style={{height: "100vh", width: "100%"}}>
      <PdfViewer document={`/books/${isbn}.pdf`} isbn={isbn}/>
    </div>
  );
}
