import React, { Component } from "react";
// import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/esm/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../logo.svg";
import { FaSun, FaMoon } from 'react-icons/fa';

class NavBar extends Component {
  state = {
    theme: 'light',
  };
  render() {
    return (
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <Navbar.Brand href="#home">
            <img
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0 justify-content-center"
              style={{ maxHeight: "100px", marginLeft: "450px" }}
              navbarScroll
              activeKey={`/${this.props.active}`}
              variant="pills"
            >
              <Nav.Item className="mx-2">
                <Nav.Link href="/">Home</Nav.Link>
              </Nav.Item>
              <Nav.Item className="mx-2">
                <Nav.Link href="/igcse">E-Books</Nav.Link>
              </Nav.Item>
              <Nav.Item className="mx-2">
                <NavDropdown title="Subjects" id="navbarScrollingDropdown">
                  <NavDropdown.Item href="#action3">Math</NavDropdown.Item>
                  <NavDropdown.Item href="#action4">Physics</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action5">
                    Something else here
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav.Item>
              <Nav.Item className="mx-2">
                <Nav.Link href="#" disabled>
                  Link
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Button style={{ marginLeft: "calc(100% + 169px)" }} variant="outline-dark" onClick={() => {
                  if (this.state.theme === 'dark') {
                    this.setState({theme: 'light'});
                  } else {
                    this.setState({theme: 'dark'})
                  }
                }}>
                  {
                    this.state.theme === 'light' ? (
                      <FaSun style={{marginBottom: "4px"}}></FaSun>
                    ) : (
                      <FaMoon style={{marginBottom: "4px"}}></FaMoon>
                    )
                  }
                </Button>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default NavBar;
