import React from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const PdfViewer = (props) => {
  const transform = (slot) =>
    Object.assign(Object.assign({}, slot), {
      Open: () => React.createElement(React.Fragment, null),
    });
  const renderToolbar = (Toolbar) =>
    React.createElement(Toolbar, null, renderDefaultToolbar(transform));
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
      renderToolbar,
  });
  const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance;

  return (
    <Worker workerUrl="/pdf.worker.min.js">
      <Viewer
        fileUrl={props.document}
        plugins={[defaultLayoutPluginInstance]}
        initialPage={localStorage.getItem(`lastPage-${props.isbn}`)}
        onPageChange={(e) => {
          localStorage.setItem(`lastPage-${props.isbn}`, e.currentPage)
        }}
      />
    </Worker>
  );
};

export default PdfViewer;
