import React, { useEffect, useState } from "react";
import {
  createTheme,
  Link,
  NextUIProvider,
  styled,
  Navbar,
  Button,
  Dropdown,
  getDocumentTheme,
  changeTheme,
} from "@nextui-org/react";
import { motion } from "framer-motion";
import { FaSun, FaMoon } from "react-icons/fa";
import { GiWhiteBook } from 'react-icons/gi';

const darkTheme = createTheme({
  type: "dark",
  theme: {
    colors: {
    }
  },
});

const lightTheme = createTheme({
  type: "light",
  theme: {
    colors: {},
  },
});

const Box = styled("div", {
  boxSizing: "border-box",
});

const Layout = ({ children }) => (
  <Box
    css={{
      maxW: "100%",
    }}
  >
    {children}
  </Box>
);

const StyledApp = styled("div", {
  dflex: "center",
  flexDirection: "column",
  minHeight: "100vh",
});

export default function Home() {
  const [isDark, setIsDark] = useState(false);

  useEffect(() => {
    let theme = window.localStorage.getItem("data-theme");
    setIsDark(theme === "dark");

    const observer = new MutationObserver((mutation) => {
      let newTheme = getDocumentTheme(document?.documentElement);
      setIsDark(newTheme === "dark");
    });

    observer.observe(document?.documentElement, {
      attributes: true,
      attributeFilter: ["data-theme", "style"],
    });

    return () => observer.disconnect();
  }, []);

  const handleChange = () => {
    if (window.localStorage.getItem("data-theme") === "dark") {
      window.localStorage.setItem("data-theme", "light");
      changeTheme("light");
    } else {
      window.localStorage.setItem("data-theme", "dark");
      changeTheme("dark");
    }
  };

  return (
    <NextUIProvider theme={isDark ? darkTheme : lightTheme}>
      <Layout>
        <Navbar isBordered variant={"static"}>
          <Navbar.Brand>
            <GiWhiteBook />
          </Navbar.Brand>
          <Navbar.Content
            hideIn="xs"
            activeColor={"primary"}
            variant={"highlight-rounded"}
          >
            <Navbar.Link isActive href="#">
              Home
            </Navbar.Link>
            <Navbar.Link href="/igcse">E-Books</Navbar.Link>
            <Navbar.Link href="/igcse">
              <Dropdown>
                <Dropdown.Button light>Subjects</Dropdown.Button>
                <Dropdown.Menu
                  aria-label="Dynamic Actions"
                  items={[
                    {
                      key: "cs",
                      name: "Computer Science",
                    },
                  ]}
                >
                  {(item) => (
                    <Dropdown.Item
                      key={item.key}
                      color={item.key === "delete" ? "error" : "default"}
                    >
                      {item.name}
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Navbar.Link>
          </Navbar.Content>
          <Navbar.Content>
            <Navbar.Item>
              <Button auto light as={Link} onPress={handleChange}>
                {
                  isDark ? <FaMoon></FaMoon> : <FaSun></FaSun>
                }
              </Button>
            </Navbar.Item>
          </Navbar.Content>
        </Navbar>
      </Layout>
      <StyledApp className="App">
        <h1 className="display-1 text-center">Every E-Book.</h1>
        <p className="display-3 text-muted text-center">For Free.</p>
        <motion.div whileHover={{ scale: 1.2 }}>
          <Button
            color="gradient"
            rounded
            shadow
            bordered
            css={{
              marginTop: "$15",
            }}
          >
            Get Started
          </Button>
        </motion.div>
      </StyledApp>
    </NextUIProvider>
  );
}
