import React, { Component } from "react";
import BookCard from "../components/BookCard";
import NavBar from "../components/Navbar";
import books from "..";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import "./igcse.css";

class Igcse extends Component {
  state = {
    subjectTitle: "Choose a subject",
    subjectType: "Choose a type",
    subject: "",
    type: "",
    book: null,
  };
  render() {
    return (
      <div>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css"
          integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        />
        <NavBar active="igcse"></NavBar>
        <div className="title">
          <h1
            className="display-3 text-center"
            style={{
              marginTop: "100px",
            }}
          >
            Choose an E-book:{" "}
          </h1>
        </div>
        <br></br>
        <div className="container">
          <div className="row">
            <div className="col">
              <DropdownButton
                id="subject-dropdown"
                title={this.state.subjectTitle}
                className="text-center"
                style={{
                  marginTop: "20px",
                }}
                onSelect={(key, event) => {
                  this.setState({ subjectTitle: key, subject: key });
                }}
              >
                <Dropdown.Item eventKey="Computer Science">
                  Computer Science
                </Dropdown.Item>
                <Dropdown.Item eventKey="Math">Math</Dropdown.Item>
                <Dropdown.Item eventKey="Physics">Physics</Dropdown.Item>
              </DropdownButton>
            </div>
            <div className="col">
              <DropdownButton
                id="subject-dropdown"
                title={this.state.subjectType}
                className="text-center"
                style={{
                  marginTop: "20px",
                }}
                onSelect={(key) => {
                  this.setState({ subjectType: key, type: key });
                }}
              >
                <Dropdown.Item eventKey="Coursebook">Coursebook</Dropdown.Item>
                <Dropdown.Item eventKey="Workbook">Workbook</Dropdown.Item>
                <Dropdown.Item eventKey="Study and Revision Guide">
                  Study and Revision Guide
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
        </div>
        {this.state.subject !== "" && this.state.type !== "" && (
          <div className="ebooks">
            <h1 className="display-6 text-center strike-thru">
              {this.state.subject}
            </h1>
            {books.filter(
              (book) =>
                book.subject === this.state.subject &&
                book.type === this.state.type
            ).length > 0 && (
              <div className="container">
                <div className="row">
                  {books
                    .filter(
                      (book) =>
                        book.subject === this.state.subject &&
                        book.type === this.state.type
                    )
                    .map((book, i) => {
                      return (
                        <div className="col align-self-center" key={i}>
                          <BookCard
                            title={book.title}
                            key={i}
                            isbn={book.isbn}
                            author={book.author}
                            img={`/covers/${book.isbn}.webp`}
                          ></BookCard>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Igcse;
