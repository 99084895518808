import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import Igcse from "./pages/igcse";
import ViewBook from "./pages/viewBook";
import Home from "./pages/Home";

const books = [
  {
    isbn: 9781398318281,
    title:
      "Cambridge IGCSE & O Level Computer Science Student's Book Second Edition",
    subject: "Computer Science",
    author: "Watson, D and Williams, H",
    type: "Coursebook",
    link: "https://heyzine.com/flip-book/e7b3266e46.html",
  },
  {
    isbn: 9781398318496,
    subject: "Computer Science",
    title:
      "Cambridge IGCSE and O Level Computer Science Algorithms, Programming and Logic Workbook",
    author: "Watson, D and Williams, H",
    type: "Workbook",
    link: "https://heyzine.com/flip-book/6d5d593d73.html",
  },
  {
    isbn: 9781398318472,
    subject: "Computer Science",
    title:
      "Cambridge IGCSE and O Level Computer Science Computer Systems Workbook",
    author: "Watson, D and Williams, H",
    type: "Workbook",
    link: "https://heyzine.com/flip-book/12d7825f66.html",
  },
  {
    isbn: 9781398318489,
    subject: "Computer Science",
    author: "Watson, D and Williams, H",
    type: "Study and Revision Guide",
    title:
      "Cambridge IGCSE and O Level Computer Science Study and Revision Guide Second Edition",
    link: "https://heyzine.com/flip-book/9ec2f24c10.html",
  },
];

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/igcse",
    element: <Igcse></Igcse>,
  },
  {
    path: "/book/:isbn",
    element: <ViewBook />,
  },
  {
    path: "/test",
    element: <Home></Home>
  }
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);
export default books;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
